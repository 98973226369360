import React from 'react';
import { graphql, Link } from 'gatsby';
import { TimelineToggle, Cards, SiteMetadata, TypeButtons, Banner, CategoryMenu, GoogleAds, AdvertCards } from '../components';
import { Layout } from '../layouts/Layout';
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Timeline from '../images/Timeline.svg';

export default ({ data, pageContext: { title, category, previousPagePath, nextPagePath }, ...props }) => {
  const cardAdverts = AdvertCards(4);

  return (
    <Layout category={category || title}>
      <SiteMetadata
        title={category}
        description={`${category} streamings that are being offered online for free, or for a small donation fee, during the COVID-19 pandemic`}
        path={props.location.pathname}
      />
      <div className="w-full hidden md:flex justify-center items-center">
        <OutboundLink href="https://www.awin1.com/cread.php?s=2586653&v=18026&q=371531&r=268283" target="_blank"
          rel="noopener noreferrer">
          <img
            src="https://www.awin1.com/cshow.php?s=2586653&v=18026&q=371531&r=268283"
            border="0"
            alt="category || title"
          />
        </OutboundLink>
      </div>
      <div className="flex flex-row justify-center items-center w-full lg:justify-between">
        <TimelineToggle category={category} />
        <CategoryMenu />
      </div>
      <Cards nodes={data.items.nodes} Advert={cardAdverts} />

      <div className="container pt-4 pb-12 overflow-hidden justify-center align-center flex leading-tight text-white">
        {previousPagePath && <Link to={previousPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
          « Previous
        </Link>}
        {nextPagePath && <Link to={nextPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
          Next »
        </Link>}
      </div>
      <TypeButtons title="You might also be interested in..."></TypeButtons>
      <Banner
        title="Check out our Timeline View"
        description={`See ${category ? category : 'all'} streamings by chronological order`}
        svg={<Timeline className="w-full h-56 mb-8" />} />
      <GoogleAds slotId="8260560097"/>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PaginatedStreamingPageQuery($tableName: String!, $category: [String], $series: [String], $expiredArray: [Int] = [0, 1], $skip: Int!, $limit: Int!) {
    items: allAirtable(
      filter: { table: { eq: $tableName },
                data: { tags: { in: $category },
                        expired: { in: $expiredArray }
                        series: { in: $series } } }
      sort: { fields: data___lastModified, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        data {
          country
          tags
          image {
            ...CardImageFragment
          }
          name
          slug
          summary
          expiry
          expired
        }
      }
    }
  }
`;
